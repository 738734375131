<template>
  <shipment-input
    :is-mobile="viewport.isLessThan('md')"
    v-bind="props"
    @error="emits(Emits.ERROR, $event)"
    @input="emits(Emits.INPUT, $event)"
    @focus="emits(Emits.FOCUS)"
    @submit="handleSubmit($event)"
  />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { Emits } from '~common/enums'
import { routerPushSsr } from '~common/helpers'
import type { ShipmentNumberInputEmits, ShipmentNumberInputProps } from '~common/types'

const ShipmentInput = defineAsyncComponent(
  () => import('~components/pages/index/ShipmentNumberInput/ShipmentInput.vue'),
)

const viewport = useViewport()
const localePath = useLocalePath()

const props = defineProps<ShipmentNumberInputProps>()
const emits = defineEmits<ShipmentNumberInputEmits>()

const handleSubmit = (value: string | Event) => {
  if (typeof value !== 'string') {
    return
  }
  const id = value.replaceAll(' ', '')
  routerPushSsr(localePath({ name: 'id', params: { id } }))
}
</script>
